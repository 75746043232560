import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router/login';
import '@/style/common-class.less';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI, { size: 'mini' });

import { Message } from 'element-ui';
Vue.prototype.$message = function (msg) {
  return Message({
    message: msg,
    duration: 1500
  });
};
//分别对success、warning和error等样式进行设置
Vue.prototype.$message.success = function (msg) {
  return Message.success({
    message: msg,
    duration: 1500
  });
};
Vue.prototype.$message.warning = function (msg) {
  return Message.warning({
    message: msg,
    duration: 1500
  });
};
Vue.prototype.$message.error = function (msg) {
  return Message.error({
    message: msg,
    duration: 1500
  });
};

window._vm = new Vue({
  router,
  render: h => h(App)
}).$mount('#app');

import { commonRequest } from '@/common-api';

function getConfigData() {
  commonRequest.post('/admin/common/getHomeConfig', {}).then(res => {
    // 创建新的 link 标签
    let newLink = document.createElement('link');
    newLink.rel = 'icon';
    newLink.type = 'image/x-icon';
    newLink.href = res.data.icon;

    // 获取并移除原有的 favicon link（如果有多个，可能需要更精确的选择器）
    let oldLinks = document.querySelectorAll('link[rel="icon"]');
    for (var i = 0; i < oldLinks.length; i++) {
      oldLinks[i].parentNode.removeChild(oldLinks[i]);
    }

    // 将新创建的 link 插入到 head 中
    document.head.appendChild(newLink);
  });
}

getConfigData();
