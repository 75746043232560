import axios from 'axios';
import qs from 'qs';
import { Message } from 'element-ui';
import fileDownload from 'js-file-download';
import Cookies from 'js-cookie';
import { handleLogout } from './common';
import md5 from 'js-md5';
import Jm from '@/utils/secret';

const statusValueArr = [401, 403];

const instance = axios.create({
  timeout: 120000,
  headers: { 'Content-Type': 'application/json' },
  baseURL: ''
});
// 保存下载文件
function saveFile(binaryData, fileName, mime) {
  fileDownload(binaryData, fileName, mime);
}

instance.download = {
  get(url, params = { filename: 'unknown', mime: '' }, config) {
    instance.get(url, { ...config, responseType: 'blob' }).then(res => {
      saveFile(res.data, params.filename, params.mime);
    });
  },
  post(url, data, config = {}) {
    const { filename = 'unknown', mime = '' } = config || {};
    instance.post(url, data, { ...config, responseType: 'blob' }).then(res => {
      saveFile(res.data, filename, mime);
    });
  }
};

// upload
instance.upload = (url, data, config = {}, params = {}) => {
  const formData = new FormData();
  data.forEach(item => {
    formData.append('file', item.value, item.name || item.filename);
  });
  if (params && Object.keys(params).length > 0) {
    for (const m in params) {
      if (Object.property.hasOwnProperty.call(params, m)) {
        const key = m;
        const value = params[m];
        formData.append(key, value);
      }
    }
  }
  return instance.request({
    ...config,
    method: 'post',
    url,
    data: formData,
    headers: { ...config.headers, 'Content-Type': 'multipart/form-data' }
  });
};

instance.formdata = (url, data, method = 'post') => {
  const formData = qs.stringify(data); // 会将汉字encode
  return instance.request({
    url,
    data: formData,
    method,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  });
};

function objKeySort(obj) { //排序的函数
  var newkey = Object.keys(obj).sort();
  //先用Object内置类的keys方法获取要排序对象的属性名，再利用Array原型上的sort方法对获取的属性名进行排序，newkey是一个数组
  var newObj = {};//创建一个新的对象，用于存放排好序的键值对
  for (var i = 0; i < newkey.length; i++) {//遍历newkey数组
  newObj[newkey[i]] = obj[newkey[i]];//向新创建的对象中按照排好的顺序依次增加键值对
  }
  return newObj;//返回排好序的新对象
}

// 请求拦截器
instance.interceptors.request.use(config => {
  const token = JSON.parse(sessionStorage.getItem("loginInfo")) ? JSON.parse(sessionStorage.getItem("loginInfo")).token : '';
  const { headers, params } = config;
  let time = new Date().getTime();
  config.params = { ...params, timestamp: time};
  // add token if need
  headers.set('token', token);
  headers.set('timestamp', time);
  // 前端签名 暂时屏蔽对接完成后开启
  // if (config.url === '/admin/common/getVerifyCode' || config.url === '/admin/common/getHomeConfig' || config.url === '/admin/common/upload'
  // || config.url === '/admin/common/download' || config.url === '/admin/qly.qly_monitor/config') {
  //   console.log('不加密')
  // } else {
  //   if (config.data) {
  //     let bodyParams = objKeySort(config.data);
  //     let bodyParamsArray = []
  //     for (let key in bodyParams) {
  //       bodyParamsArray.push(key + "=" + bodyParams[key])
  //     }
  //     let bodyParamsStr = bodyParamsArray.join('&') + '&verify_sign_time_now=' + time
  //     let signStr = md5(bodyParamsStr).toUpperCase()
  //     headers.set('sign', signStr);
  //     config.data = {
  //       data: Jm.encrypt(JSON.stringify(config.data), signStr)
  //     }
  //   }
  // }
  return config;
});

// 响应拦截器
instance.interceptors.response.use(
  response => {
    if (response.data.code === 502) {
      Message({
          showClose: true,
          message: '登录信息已过期,请重新登录!',
          type: 'warning'
      })
      setTimeout(() => {
        let domain = window.location.host;
        window.location.href = "http://" + domain + "/#/login";
      }, 2500)
      return Promise.reject(response.data);
    } else {
      // 接口返回参数解密 暂时屏蔽对接完成后开启
      // if (response.config.url === '/admin/common/getVerifyCode' || response.config.url === '/admin/common/upload'
      // || response.config.url === '/admin/common/download' || response.config.url === '/admin/qly.qly_monitor/config') {
      //   console.log('不解密')
      // } else {
      //   let timestamp = response.data.timestamp.toString()
      //   let key = md5(timestamp).toUpperCase()
      //   const datas = Jm.decrypt(response.data.data, key)
      //   response.data.data = JSON.parse(datas)
      //   console.log('datas', response.data.data)
      // }
      return Promise.resolve(response);
    }
  },
  error => {
    if (error && error.response && error.response.status) {
      let errMsg = '';
      if (error.response.data) {
        const { errorMsg, message } = error.response.data;
        errMsg = errorMsg || message;
      }
      // Message.error(errMsg);
      // if (statusValueArr.indexOf(error.response.status) > -1) {
      //   handleLogout();
      // }
    }
    return Promise.reject(error);
  }
);

export default instance;
