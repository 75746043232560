import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

//解决vue路由重复导航错误
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue')
  },
  {
    path: '/entry',
    name: 'entry',
    component: () => import('@/views/login/entry.vue')
  },
  { path: '', redirect: '/login' }
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
});

export default router;
