/*
 * @Description: 网络请求的简单封装
 * @Author: lei.pang
 * @Date: 2022-07-26 14:41:26
 * @LastEditors: lei.pang
 * @LastEditTime: 2022-07-29 13:19:36
 */

import http from '@/utils/http';
/**
 * 公共请求简单封装
 */
export const commonRequest = {
  async post(path, params) {
    const res = await http.post(path, {...params, app_id: sessionStorage.getItem("app_id") ? sessionStorage.getItem("app_id") : ''});
    return res.data;
  },
  async get(path, params) {
    const res = await http.get(path, {...params, app_id: sessionStorage.getItem("app_id") ? sessionStorage.getItem("app_id") : ''});
    return res.data;
  },
  async put(path, params) {
    const res = await http.put(path, {...params, app_id: sessionStorage.getItem("app_id") ? sessionStorage.getItem("app_id") : ''});
    return res.data;
  },
  async delete(path, params) {
    const res = await http.delete(path, {...params, app_id: sessionStorage.getItem("app_id") ? sessionStorage.getItem("app_id") : ''});
    return res.data;
  },
  /**
   * 公共上传
   * @param path 上传路径
   * @param file 文件信息
   * @param config 配置信息
   * @param params 额外参数
   */
  async uploadFile(path, file, config, params) {
    const res = await http.upload(path, file, config, params);
    return res.data;
  },
  /**
   * get方式下载
   * @param path 路径
   * @param config 配置参数
   */
  async downloadFile(path, config) {
    await http.download.get(path, config);
  },

  async downloadFilePost(path, data) {
    await http.download.post(path, data);
  },
  /**
   * 文件导出：文件内容动态生成
   * @param path 路径
   * @param params 参数
   * @param config 导出的文件信息
   */
  async exportFile(path, params, config) {
    await http.download.post(path, params, config);
  }
};
