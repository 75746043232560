import CryptoJS from 'crypto-js'
 
const AES_IV = '1472583697539515' // 密钥偏移量，16个字符
const iv = CryptoJS.enc.Utf8.parse(AES_IV)
export default {
  // 加密
  encrypt(data, key) {
      const srcs = CryptoJS.enc.Utf8.parse(data)
      const keys = CryptoJS.enc.Utf8.parse(key)
      const encrypted = CryptoJS.AES.encrypt(srcs, keys, {
          iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
      })
      return encrypted.toString()
  },
  // 解密
  decrypt(data, key) {
      const keys = CryptoJS.enc.Utf8.parse(key)
      const decrypted = CryptoJS.AES.decrypt(data, keys, {
          iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
      })
      return CryptoJS.enc.Utf8.stringify(decrypted).toString()
  }
}
