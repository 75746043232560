import { commonRequest } from '@/common-api';
import { Message } from 'element-ui';
/**
 * 根据某个字段对数组去重
 * @param array 原数组
 * @param key 去重依据的字段名
 */
export function duplicateRemove(array, key) {
  const values = Array.from(new Set(array.map(arr => arr[key])));
  const arrayCorrect = [];
  values.forEach(value => {
    const filterArr = array.filter(arr => arr[key] === value);
    arrayCorrect.push(filterArr[0]);
  });
  return arrayCorrect;
}

/**
 * 一维数组根据个数转为二维数组
 * @param array：原数组
 * @param n：二维数组中的最大个数
 * @returns 二维数组
 */
export function arrayToNArray(array, n = 8) {
  const arrayLength = array.length;
  const num = arrayLength % n === 0 ? arrayLength / n : Math.floor(arrayLength / n + 1);
  const arrayCorrect = [];
  for (let index = 0; index < num; index++) {
    const element = array.slice(index * n, (index + 1) * n);
    arrayCorrect.push(element);
  }
  return arrayCorrect;
}

/**
 * 清除所有cookie
 */
export function clearAllCookie() {
  const keys = document.cookie.match(/[^ =;]+(?==)/g);
  if (keys) {
    for (let i = keys.length; i--; )
      document.cookie = keys[i] + '=0;expires=' + new Date(0).toUTCString();
  }
}

/**
 * 登出
 */
export function handleLogout() {
  commonRequest.post('/admin/login/logout').then(res => {
    if (res.code === 200) {
      clearAllCookie();
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = '/login';
    } else if (res.code === 502){
      clearAllCookie();
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = '/login';
    } else {
      this.$message.error(res.msg);
    }
  }).catch(event => {
  });;
}

/**
 * 模板下载: 服务器文件，直接下载
 * @param name 模板名称，不包含后缀名
 */
export function templateDownload(name) {
  window.open(`/template/${name}.xlsx`, '_self');
}

/**
 * 从window.location.pathname中解析出当前子系统名称
 */
export function parseSubSystemFromHref() {
  const pathnameStr = window.location.pathname.split('/')[1];
  const system = pathnameStr.split('-')[1];
  return system;
}

/**
 * 简单的循环处理menu数据，将对象中的字段精简
 * @param menuItem
 * @returns IMenuRouter
 */
export function handleAllMenuData(menuItem, path = '') {
  const { type, name, href, icon, permission } = menuItem;
  let { childrenMenu } = menuItem;
  if (childrenMenu.length > 0) {
    childrenMenu = childrenMenu.map(childrenMenuItem => {
      return handleAllMenuData(childrenMenuItem, path ? `${path}/${href}` : href);
    });
  }
  return {
    type,
    name,
    href,
    path: path ? `${path}/${href}` : href,
    icon,
    permission,
    childrenMenu
  };
}

